<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <!-- <v-col cols="12">
          <h5 class="text-h3 text-typo font-weight-bold ms-4 mb-3">
            {{$t('stage.Stages')}}
          </h5>
        </v-col> -->
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{ $t("stage.Stages") }}</v-toolbar-title>
                <button
                  v-if="
                    $store.getters['auth/str_per'].indexOf('stage-create') > -1
                  "
                  class="py-5 mb-0 ms-auto"
                  @click="add_stage()"
                >
                  <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
                </button>
              </v-app-bar>
              <FormFilter
                :filters="filters"
                @get_data="get_stages"
                @reset="resetFilters"
              ></FormFilter>
              <Table
                :loading="loading"
                :headers="header"
                :items="stages"
                :paginate="paginate"
                :btn_table="btn_table"
                :btns="btns"
                @action="action"
                :btn_loading="btn_loading"
                @get_data_paginate="get_data_paginate"
                @set_option="set_option"
                @change_pre_page="ChangePrePage"
                ref="table"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <Modal :data="modal_data"></Modal>
    </v-container>
  </div>
</template>
<script>
import FormFilter from "../Components/Filter.vue";
import Table from "../Components/PaginationTable.vue";
import Notify from "../Components/New/Notify.vue";
import Modal from "../Components/New/Modal.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("stage");
export default {
  name: "Stage-Page",
  components: {
    Table,
    Notify,
    FormFilter,
    Modal,
  },
  data() {
    return {
      paginate: {
        page: 1,
        itemsPerPage: 0,
        total: 0,
      },
      filters: [],
      options: {},
      btn_table: {
        excel: false,
        print: false,
      },
      btn_loading: {
        excel: false,
        print: false,
      },
      modal_data: {
        size: "500px",
        title: "",
      },
      loading: true,
      message: {
        msg: null,
        type: null,
      },
      btns: [
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "stage-update",
        },
        // {type:'icon',text : "destroy",color:'bg-gradient-danger',icon:'mdi-delete'},
      ],
      header: [
        {
          text: this.$i18n.t("stage.title in english"),
          value: "name",
          align: "center",
        },
        {
          text: this.$i18n.t("stage.title in arabic"),
          value: "name_ar",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      form_data: {
        name: "",
      },
      form_style: [
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("stage.title in english"),
          error: null,
          value_text: "name",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("stage.title in arabic"),
          error: null,
          value_text: "name_ar",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
      ],
    };
  },
  computed: {
    ...mapState(["stages"]),
  },
  methods: {
    ...mapActions(["getStages", "destroyStage"]),
    set_option(val) {
      this.options = val;
    },
    get_data_paginate(page) {
      this.paginate.page = page;
      this.get_stages();
    },
    ChangePrePage(page) {
      this.paginate.itemsPerPage = parseInt(page);
      this.paginate.page = 1;
      this.get_stages(false, "pre_page");
    },
    resetFilters() {
      this.paginate.page = 1;
      this.get_stages(true);
    },
    get_stages(reset = false, type = null) {
      this.loading = true;
      const formData = new FormData();
      if (!reset) {
        this.filters.filter(function (filter) {
          formData.append(filter.name, filter.value);
        });
      }
      if (type == "pre_page") {
        formData.append("paginate", this.paginate.itemsPerPage);
      }
      this.getStages({ page: this.paginate.page, data: formData }).then(
        (response) => {
          this.loading = false;
          this.paginate.total = response.data.stages.total;
          this.paginate.itemsPerPage = parseInt(response.data.stages.per_page);

          if (reset) {
            this.filters = response.data.filters;
            this.options = {};
            this.reset_option = true;
            this.$refs.table.resetOption();
          }
        },
        (error) => {
          console.log(error);
          this.loading = false;
          if (error.response.status != 401) {
            this.$store.dispatch("form/setNotify", {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            });
          }
        }
      );
    },
    action(id, name, item) {
      if (name == "edit") {
        this.edit_stage(item);
      } else if (name == "destroy") {
        this.destroy_stage(id);
      }
    },
    add_stage() {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title = this.$i18n.t("stage.New Stage");
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.commit("form/INFO_CALL", {
        name: "stage/addStage",
      });
    },
    edit_stage(item) {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title =
        this.$i18n.t("stage.Edit Stage") + " # " + item.name_lang;
      this.$store.dispatch("form/setData", this.form_style);
      this.form_data.name = item.name;
      this.form_data.name_ar = item.name_ar;
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.commit("form/INFO_CALL", {
        name: "stage/editStage",
        id: item.id,
      });
    },
    destroy_stage(id) {
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("general.Yes, delete it!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.destroyStage(id).then(
            (response) => {
              // console.log(response)
              this.$swal.fire(
                this.$i18n.t("general.Deleted!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
  },
  mounted() {
    this.get_stages(true);
    document.title = this.$i18n.t("stage.Stages");
  },
};
</script>
